/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import i18n from './src/utils/i18n';
import { I18nextProvider } from 'react-i18next';
import { languages } from './src/utils/languages';

const RootElementWrapper = ({ element }) => (
  <I18nextProvider i18n={i18n}>{element}</I18nextProvider>
);
export const wrapRootElement = RootElementWrapper;

export const onRouteUpdate = ({ location }, pluginOptions = {}) => {
  let currentLang = location.pathname.split('/')[1]; // Extract language from URL
  if (!Object.keys(languages).includes(currentLang)) {
    currentLang =
      Object.keys(languages).find((lang) => languages[lang].default) || 'en';
  }

  if (i18n.language !== currentLang) {
    window.location.reload();
  }

  // Source https://raw.githubusercontent.com/gatsbyjs/gatsby/master/packages/gatsby-plugin-google-analytics/src/gatsby-browser.js
  if (process.env.NODE_ENV !== `production` || typeof ga !== `function`) {
    return null;
  }

  // const pathIsExcluded =
  //     location &&
  //     typeof window.excludeGAPaths !== `undefined` &&
  //     window.excludeGAPaths.some(rx => rx.test(location.pathname))
  //
  // if (pathIsExcluded) return null

  // wrap inside a timeout to make sure react-helmet is done with it's changes (https://github.com/gatsbyjs/gatsby/issues/9139)
  // reactHelmet is using requestAnimationFrame: https://github.com/nfl/react-helmet/blob/5.2.0/src/HelmetUtils.js#L296-L299
  const sendPageView = () => {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined;
    window.ga(`set`, `page`, pagePath);
    window.ga(`send`, `pageview`);
  };

  // Minimum delay for reactHelmet's requestAnimationFrame
  const delay = Math.max(32, pluginOptions.pageTransitionDelay || 0);
  setTimeout(sendPageView, delay);

  return null;
};
